import $ from 'jquery'

$(document).ready( initCTA )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=cta', initCTA)
}

function initCTA(){
  $('.js-pause').on("click", function () {
    let btn = $(this);
    let video = $(this).parent().parent().find('video');
    let btntext = btn.find('.u-sr-only');
    let btnicon = btn.find('.cta__pause-icon');
    console.log(btntext);
    if (video[0].paused) {
      video[0].play();
      btntext.text("Pause video");
      btnicon.html('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FAA530"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.80005 7.74683V16.2802C8.80005 16.869 9.2779 17.3469 9.8667 17.3469C10.4555 17.3469 10.9334 16.869 10.9334 16.2802V7.74683C10.9334 7.15803 10.4555 6.68018 9.8667 6.68018C9.2779 6.68018 8.80005 7.15803 8.80005 7.74683ZM13.0667 7.74683V16.2802C13.0667 16.869 13.5446 17.3469 14.1334 17.3469C14.7222 17.3469 15.2 16.869 15.2 16.2802V7.74683C15.2 7.15803 14.7222 6.68018 14.1334 6.68018C13.5446 6.68018 13.0667 7.15803 13.0667 7.74683Z" fill="#1B1815"/></svg>');
    } else {
      video[0].pause();
      btntext.text("Play video");
      btnicon.html('<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15" fill="#FAA530"/><path d="M10 19.723C10.0274 20.964 11.254 21.7156 12.3289 21.1247L20.9714 16.0422C21.4421 15.765 21.7615 15.2571 21.7615 14.662C21.7615 14.0668 21.4421 13.5589 20.9714 13.2817L12.3289 8.20634C11.254 7.61538 10.0274 8.35974 10 9.60099V19.723Z" fill="#1B1815"/></svg>');
    }
  });
}
